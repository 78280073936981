import { addBigScreen, editBigScreen, getBigScreen, templateList, stateList } from "@lib";
export default {
  data() {
    return {
      templateList: [],
      stateList: [],
      formData: {
        id:"",
        zhName:"",
        enName:"",
        templateId:"",
        templateName:"",
        type: 0
      }
    };
  },
  created() {
    this.getTemplate()
    this.getState()
    let { id = '' } = this.routerParams
    this.formData.id = id
    this.formData.id&&this.getData() 
  },
  methods: {
    async getData(){
      try {
        let result = await getBigScreen({ id: this.formData.id});
        this.formData = result
      } catch (error) {
        console.error(error);
      }
    },
    async getState(){
      try{
        let result = await stateList()
        this.stateList = result
      } catch (error) {
        console.error(error);
      }
    },
    async getTemplate() {
      try{
        let result = await templateList()
        this.templateList = result
      } catch (error) {
        console.error(error);
      }
    },
    onSubmit() {
      if(this.formData.id){
        this.onEdit()
      }else{
        this.onAdd()
      }
    },
    async onAdd() {
      try {
        let {success} = await addBigScreen({ ...this.formData, type: this.stateList.find(m=>m.name===this.formData.type).code});
        success&&this.$message.success('添加成功')
      } catch (error) {
        console.error(error);
      }
    },
    async onEdit() {
      try {
        let {success} = await editBigScreen({ ...this.formData, type: this.stateList.find(m=>m.name===this.formData.type).code});
        success&&this.$message.success('修改成功')
      } catch (error) {
        console.error(error);
      }
    },
    onTemplate(e){
      this.formData.templateName = this.templateList.find(m=>m.id===e).name
      console.log(e)
    },
    onBack(){
      this.$router.back()
    }
  },
};
